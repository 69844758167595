import './Home.css';
import * as React from 'react';
import sunSpaceBrands from '../../assets/tmp/sunspaceBrands.png';
import behindTheScenes from '../../assets/tmp/behindTheScenes.png';
import artistSpotlight from '../../assets/tmp/artistSpotlight.png';
import brandSpotlight from '../../assets/tmp/brandSpotlight.png';
import { Route } from 'react-router-dom';

export default function Home() {
  return (
    <div>
      <img className="placeholderImage" src={sunSpaceBrands} />
      <img className="placeholderImage" src={behindTheScenes} />
      <img className="placeholderImage" src={artistSpotlight} />
      <img className="placeholderImage" src={brandSpotlight} />
    </div>
  );
}
