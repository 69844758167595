import './footer.css'
import * as React from 'react';
import { useTheme } from '@mui/material';

export default function Footer () {

    const theme = useTheme();

    const footerStyles = {backgroundColor:theme.palette.background.main}

    return (
        <footer style={footerStyles}> 
            <p> this is a shoe</p>
        </footer>
    )
    
}