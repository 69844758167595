import './officephoto.css';
import * as React from 'react';

import Masonry from 'react-masonry-css';
import sweetDreamsBooth from '../../assets/sweetdreamsbooth.jpg';
import bTS1 from '../../assets/bts1.jpg';
import miniInfinity from '../../assets/miniinfinity.jpg';
import bTS2 from '../../assets/bts2.jpg';
import miniInfinity2 from '../../assets/miniinfinity2.jpg';
import miniInfinityBefore from '../../assets/miniinfinitybefore.jpg';
import bTS3 from '../../assets/bts3.jpg';



  
export default function Gallery () {

    const breakpointColumnsObj = {
      default: 4,
      1100: 3,
      700: 2,
      500: 1
      };
  return (
    <Masonry breakpointCols={breakpointColumnsObj} 
    className="my-masonry-grid" 
    columnClassName="my-masonry-grid_column">
         <div>
            <img id="1" className="galleryPhotos" src={bTS1} />
             <img id="2" className="galleryPhotos" src={bTS2} />
             <img id="3" className="galleryPhotos" src={bTS3} />
             <img id="4" className="galleryPhotos" src={miniInfinity} />
             <img id="5" className="galleryPhotos" src={miniInfinity2} />
             <img id="6" className="galleryPhotos" src={sweetDreamsBooth} />
             <img id="7" className="galleryPhotos" src={miniInfinityBefore} />
        </div>
    </Masonry>
  );
}
