import './topbar.css';
import * as React from 'react';
import { Tab, Tabs } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import Button from '@mui/material/Button';
import { styled, useTheme } from '@mui/material';
import logo from '../../assets/logo-transparent.png';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Drawer from '@mui/material/Drawer';
import Divider from '@mui/material/Divider';
import { useEffect, useState } from 'react';

import MuiAppBar from '@mui/material/AppBar';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

const DEFAULT_ROUTE = '';

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

export default function Topbar() {
  const location = useLocation();
  const navigate = useNavigate();
  const theme = useTheme();

  const TabList = (params) => (
    <Tabs
      textColor="secondary"
      indicatorColor="secondary"
      value={currentPath}
      onChange={(_, value) => navigate(value)}
      {...params}
    >
      <Tab
        sx={{ color: theme.palette.primary.contrastText }}
        color="inherit"
        label="Home"
        value="/"
      />
      <Tab
        sx={{ color: theme.palette.primary.contrastText }}
        color="inherit"
        label="Spaces"
        value="/spaces"
      />
      <Tab
        sx={{ color: theme.palette.primary.contrastText }}
        color="inherit"
        label="Music"
        value="/music"
      />
      <Tab
        sx={{ color: theme.palette.primary.contrastText }}
        color="inherit"
        label="Films"
        value="/films"
      />
    </Tabs>
  );

  const currentPath = location.pathname === '/' ? `/${DEFAULT_ROUTE}` : location.pathname;

  const [open, setOpen] = React.useState(false);
  const handleDrawerOpen = () => setOpen(true);
  const handleDrawerClose = () => setOpen(false);

  const [isMobile, setIsMobile] = useState(false);
  const handleResize = () => (window.innerWidth < 720 ? setIsMobile(true) : setIsMobile(false));
  useEffect(() => window.addEventListener('resize', handleResize));

  return (
    <div>
      {/* ------- Mobile App Bar -------- */}
      {isMobile && (
        <Drawer
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            '& .MuiDrawer-paper': {
              background: theme.palette.background.main,
              width: drawerWidth,
              boxSizing: 'border-box',
            },
          }}
          variant="persistent"
          anchor="left"
          open={open}
        >
          <DrawerHeader>
            <IconButton color="light" onClick={handleDrawerClose}>
              {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
            </IconButton>
          </DrawerHeader>
          <Divider sx={{ borderColor: theme.palette.primary.contrastText }} />
          <TabList
            onClick={handleDrawerClose}
            orientation="vertical"
            sx={{ borderRight: 1, borderColor: 'divider' }}
          />
        </Drawer>
      )}
      <AppBar color="background" position="static">
        <div id="top-bar">
          {isMobile && (
            <IconButton
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              sx={{
                ml: 2,
                color: theme.palette.light.main,
                mr: 2,
                ...(open && { display: 'none' }),
              }}
            >
              <MenuIcon />
            </IconButton>
          )}
          {/* ------- Desktop Tabs -------- */}

          {!isMobile && <TabList />}
          <img id="top-bar-logo" src={logo} />
          <Button
            id="contact-button"
            edge="end"
            sx={{ border: `2px solid ${theme.palette.primary.main}` }}
            color="inherit"
          >
            Get In Touch
          </Button>
        </div>
      </AppBar>
    </div>
  );
}
