import './App.css';
import * as React from 'react';
import Home from './pages/home/Home';
import Spaces from './pages/spaces/spaces';
import Topbar from './components/topbar/topbar';
import Footer from './components/footer/footer';
import Masonry from './pages/officephoto/officephoto';

import { Route, Routes } from 'react-router-dom';
import { PhotoAlbum } from 'react-photo-album';

function App() {
  return (
    <div className="App">
      <Topbar></Topbar>

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="spaces" element={<Spaces />} />
        {/* <Route path="music" element={<Music/>}/>
          <Route path="spaces" element={<Spaces/>}/> */}
      </Routes>
      <Masonry></Masonry>
      <Footer></Footer>
    </div>
  );
}

export default App;
